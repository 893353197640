<template>
  <div>
    <HeaderNavHomepage />

    <main class="main-wrapper">
      <div class="main-card">
        <section class="hero">
          <h1>
            <span class="nowrap">Winning Together:</span> HelixPay, UP OASD, and
            Nowhere to Go but UP Transform
            <span class="nowrap">UP Sports Ticketing</span>
          </h1>
          <div class="post-date">December 11, 2024</div>
        </section>

        <div class="main">
          <div class="image-container">
            <vLazyImage src="/img/articles/up-sports-ticketing.png" />
          </div>
          <div class="photo-credits">
            Photo captured by Sherwin Fiel (IG: @sherwinpill_) of UP OPTICS
          </div>

          <p>
            For sports fans in the University of the Philippines, the thrill of
            supporting their teams has always been accompanied by a frustrating
            hurdle: securing tickets. Long lines, on-site verification, and
            limited ticket availability have made attending games an uphill
            battle. But this season, Nowhere to Go but UP (NTGBUP) and the UP
            Office for Athletics and Sports Development (UP OASD) partnered with
            HelixPay to change the game, introducing a ticketing system that’s a
            win for both fans and organizers.
          </p>

          <p>
            Last April, HelixPay’s ticketing solution debuted for UP volleyball
            games, setting the stage for a smoother basketball season.
            Previously, fans endured hours of waiting in line just for a chance
            to purchase tickets, with on-site ID verification adding to the
            chaos. Now, ticket purchases are done online, allowing UP
            stakeholders to secure seats without leaving their homes. Claiming
            tickets in person is quicker than ever, as buyers arrive knowing
            their tickets are guaranteed.
          </p>

          <h2>Game-Changing Features for a Better Experience</h2>
          <p>
            HelixPay introduced several key features that have transformed how
            NTGBUP and UP OASD handle ticket sales:
          </p>

          <ul>
            <li>
              <div>Seat Recording for Efficient Claims</div>
              <div>
                Fans select their ticket tiers online, and seats are assigned
                upon claiming. Staff distribute tickets in order, while
                HelixPay’s system records seat assignments directly to customer
                accounts. This streamlined process also helps detect and block
                scalpers, ensuring tickets go to real fans.
              </div>
            </li>
            <li>
              <div>Exclusive Access for the UP Community</div>
              <div>
                Dedicated to serving UP stakeholders, tickets are exclusively
                for students, alumni, faculty, and staff. HelixPay’s exclusive
                access feature ensures that only verified registrants can
                purchase tickets, ensuring that bona fide members of the UP
                community are prioritized and preventing scalpers from securing
                tickets.
              </div>
            </li>
            <li>
              <div>Customizable Ticket Limits</div>
              <div>
                To balance demand, HelixPay enables flexible ticket limits per
                game. For high-demand events, customers are limited to two
                tickets, while lower-demand games can allow up to four tickets —
                or even unlimited purchases — to encourage wider attendance.
              </div>
            </li>
          </ul>

          <p>
            By leveraging HelixPay’s innovative features, NTGBUP and UP OASD
            have made significant strides in efforts to improve the ticketing
            process for UP sports events. Fans now spend less time in line and
            more time enjoying the games they love.
          </p>

          <p>
            This partnership exemplifies how technology can improve the ticket
            buying experience. It is a work in progress, but as the Fighting
            Maroons continue their season, improvements continue to be done so
            that fans can focus on what truly matters: cheering for their team
            without the hassle of long lines or uncertainty.
          </p>

          <p>
            Together, HelixPay, UP OASD, and NTGBUP are paving the way for a
            better fan experience — one game at a time.
          </p>

          <p>
            Talk to us at
            <a href="mailto:hello@helixpay.ph">hello@helixpay.ph</a> to learn
            more about how HelixPay can help streamline your events and
            registrations.
          </p>
        </div>
      </div>
    </main>

    <MainFooter />
  </div>
</template>

<script setup>
import HeaderNavHomepage from '@/components/HeaderNavHomepage.vue';
import MainFooter from '@/components/MainFooter.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';
import vLazyImage from 'v-lazy-image';

const metaImagePath = 'img/articles/up-sports-ticketing.png';
const articlePath = '/articles/up-sports-ticketing';

useHead(
  buildPageMeta({
    title: `Winning Together: HelixPay, UP OASD, and Nowhere to Go but UP Transform UP Sports Ticketing`,
    description: `HelixPay introduced several key features that have transformed how NTGBUP and UP OASD handle ticket sales.`,
    url: `https://www.helixpay.ph/${articlePath}`,
    image: `https://www.helixpay.ph/${metaImagePath}`,
  })
);
</script>

<style scoped>
.main-wrapper {
  background-image: url('/img/articles/NTGBUP-bg.png');
  padding: 2rem 0;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-color: #474848;
}

.main {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.main p:first-child {
  margin-top: 0 !important;
}

.main-card {
  background: white;
  margin: 0 auto;
  max-width: 880px;
  border-radius: 1rem;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.12);
}

.hero {
  padding: 4rem 1.5rem;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.hero .content {
  position: relative;
  z-index: 9;
  text-shadow: rgba(0, 0, 0, 0.25) 1px 0 5px;
}

.hero h1 {
  margin: 0 auto;
  font-size: 1.15rem;
  letter-spacing: 0.05rem;
  line-height: 1.3;
  font-weight: 700;
  max-width: 1080px;
}

.image-container {
  padding-bottom: 100%;
  background: #b5c9c4;
  position: relative;
}

.image-container img {
  width: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
}

.main strong {
  font-weight: 600;
}

.main p,
.main h2,
.main ul {
  margin: 2rem 1.5rem;
}

.main p {
  font-size: 0.9rem;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .main-wrapper {
    padding: 2rem 1.5rem;
  }
}

@media (min-width: 768px) {
  .hero {
    padding: 6rem 3rem;
  }

  .hero h1 {
    font-size: 1.75rem;
  }

  .main p,
  .main h2,
  .main ul {
    margin: 3rem auto;
    max-width: 512px;
  }

  .main p {
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  .hero h1 {
    font-size: 2rem;
  }

  .main p,
  .main h2,
  .main ul {
    max-width: 610px;
  }

  .main p {
    font-size: 1.15rem;
  }
}

.main ul li + li {
  margin-top: 18px;
}

.main ul li div:first-child {
  font-weight: 500;
  margin-bottom: 6px;
}

.main h2 {
  margin-bottom: 0;
}

.main h2 + p {
  margin-top: 1rem;
}

.hero h1 {
  font-size: 1.75rem;
  font-weight: bold;
}

.photo-credits {
  margin-top: 4px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
  opacity: 0.7;
}

.post-date {
  margin-top: 16px;
  font-size: 14px;
  opacity: 0.7;
}

strong {
  font-weight: 600;
}

a {
  color: #25a4e1;
}
</style>
